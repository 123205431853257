@import "containers/Responsive/fonts";
@import "containers/Responsive/media";
.SafariMain{
  background-color: #23d5ab;
  width: 100%;
  height: 100vh;
  transition: background-color 1000ms linear;
  h1{
    margin: 0;
  }
  .Main{
    width: 45%;
    position: absolute;
    text-align: center;
    top: 20%;
    left: 5%;
    font-size: 22px;
    @extend .font_regular;
    z-index: 1000;
  }
}
.Svg{
  position: absolute;
  right: 5%;
  top: 20%;
  width: 40%;
  z-index: 1000;
}
.Container{
  width: 100%;
  height: 100%;
  position: relative;
  .Content{
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    z-index: 2;
    text-align: center;
    font-size: 22px;
    @extend .font_regular;
  }
}
