@import "containers/Responsive/fonts";
@import "containers/Responsive/media";
.Main{
  height: 100vh;
  @include lessThan(1417px){
    font-size: 80%;
  }
}
.Content{
  width: 45%;
  position: absolute;
  top: 10%;
  left: 5%;
  h1{
  @extend .font_regular;
  font-size: 2em;
  @include lessThan(1543px){
    font-size: 1.5em;
  }
  text-align: center;

}
  p{
    font-size: 0.7em;
    @extend .font_thin;
  }
}
.Svg{
  position: absolute;
  right: 5%;
  top: 20%;
  width: 40%;
}
