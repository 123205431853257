@import "containers/Responsive/fonts";
@import "containers/Responsive/media";

.Menu{
  width: 100%;
  z-index: 1;
  font-size: 0.6em;
  ul{
    list-style: none;
    margin: 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    >li{
      display: inline-block;
      >a{
        color: black;
        text-decoration: none;
        padding: 20px 50px;
        display: block;
        font-size: 22px;
        @extend .font_regular;
        &:hover{
          background-color: rgba(255, 255, 255, 0.44);
        }
      }
    }
  }
}
.Top{
  width: 700px;
  position: fixed;
  height: 65px;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
  background-color: black;
  font-size: 1.3em;
  ul>li>a{color:white;border-radius: 20px }
}
.Contact{
  margin-top: 10px;
  @include lessThan(1024px){
    display: none;
  }
  ul>li>a{
    color:white;
  }
}

.Sidebar{
  position: relative;
  left: 40px;

  ul{
     list-style: none;
     padding: 0;
     text-align: center;
     li{         
       a{
         padding: 40px 0;
         color: white;
         text-decoration: none;
         display: block;
       }
     }
   }
}
