@import "containers/Responsive/fonts";
@import "containers/Responsive/media";

.Projects{

  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;

  text-align: center;
  @extend .font_regular;
  height: 100vh;
  h2{
    margin: 15px;
  }
}
@keyframes gradient {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.Preview{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 500px;
    height: 500px;
  }
  &>div{
    width: 600px;
    font-size: 1.7em;
  }
@include lessThan(500px){
  top: 45%;
}
}
.ScrollDown{
  position: absolute;
  font-size: 1.7em;
  bottom: 30px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  z-index: 10000;
  @include lessThan(768px){
    font-size: 1.3em;
    bottom: 10px;
  }
}
@include lessThan(1100px){
  .Preview{
    flex-direction: column;
    img{
      width: 400px;
      height: 400px;
    }
    &>div{
      width: 100%;
      font-size: 1.7em;
    }
  }
}
@include lessThan(500px){
  .Preview{
    img{
      width: 300px;
      height: 300px;
    }
    &>div{
      font-size: 1.4em;
    }
  }
}
@include lessThan(400px){
  .Preview{
    img{
      width: 200px;
      height: 200px;
    }
    &>div{
      font-size: 1em;
    }
  }
}
