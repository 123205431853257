@import "containers/Responsive/fonts";
@import "containers/Responsive/media";

.Info{
  @extend .font_light;
  position: absolute;
  top: 40%;
  width: 45%;
  left: 5%;
  height: 60vh;
  p{
  font-size: 0.8em;
  }
  @include lessThan(1024px){
    position: initial;
    width: 100%;
    height: auto;
    z-index: 2;
    .Title{
      font-size: 1.6em;
      text-align: center;
    }

  }
}
.Btn{
  padding: 10px;
  background-color: black;
  font-size: 18px;
  border: none;
  cursor: pointer;
  color: white;
  &:hover{
    background-color: #23a6d5;
    color: black;
  }
}
.Title{
  margin: 10px;
  font-size: 1.1em;
}

.SecondArt{
  width: 100%;
  height: 200px;
  display: none;
  @include lessThan(1024px){
    display: block;
  }
}
.SecondMap{
  height: 300px;
  @include lessThan(1024px){
    height: 30vh;
    margin: 20px;
  }
  Map{
    position: relative;
    top: 100%;
    transform: translateY(-100%);
  }
}

.Languages{
  text-align: center;
}

.Diploma{
  border: 1px solid black;
  height: 55px;
  @extend .font_bold;
  background-color: white;
  color: black;
  padding: 10px;
  transition: all .2s ease-in-out;
  @include lessThan(540px){
    height: 60px;
  }
  &:hover{
    background-color: black;
    color: white;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
  img{
    height: 50px;
    display: inline-block;
    float: left;
  }
  h4{
    font-size: 27px;
    margin: 0 auto 0 90px;
    @include lessThan(633px){
      font-size: 20px;
    }
  }
  strong{
    font-size: 19px;
    display: block;
    margin-left: 90px;
    @include lessThan(633px){
      display: none;
    }
  }
  small{
    float: right;
    font-size: 15px;
    color: grey;
  }
  @include lessThan(1024px){
    width: 80%;
    margin: auto;
  }
}
.DiplomaList{
  width: 100%;
  @include lessThan(1024px){
    width: 80%;
    margin: auto;
  }
  background-color: black;
  color: white;
  padding: 20px 0;
  text-align: center;
  @extend .font_light;

  ul{
    font-size: 15px;
    list-style: none;
    margin: auto;
    text-align: left;
    padding: 0;
    >li{
      padding-left: 1rem;
      text-indent: -1.2rem;
      width: 80%;
      margin: 0 auto 10px;
    }
    >li::before{
      content: "👨‍🎓 ";
    }
  }
  button{
    padding: 10px;
    border-radius: 0;
    border: none;
    background-color: white;
  }
}
.Fifth{
  img{
    width: 150px;
    border-radius: 50%;
  }
  div{
    font-size: 30px;
    @extend .font_regular;
    @include lessThan(500px){
      font-size: 20px;
    }
  }
  @include lessThan(1024px){
    text-align: center;
    width: 80%;
    margin: auto;
  }
}
