.font_thin{
  font-family: raleway, sans-serif;
  font-weight: 100;
  font-style: normal;
}
.font_regular{
  font-family: raleway, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.font_bold{
  font-family: raleway, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.font_light{
  font-family: raleway, sans-serif;
  font-weight: 300;
  font-style: normal;
}
