@import "containers/Responsive/media";

.MainMobile{
  transition: background-color 1000ms linear;
  height: 100vh;
  width: 100%;
  .Container{
    width: 100%;
    height: 100%;
    position: absolute;
    .Content{
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }
  p{
    width: 50%;
    text-align: center;
    font-size: 1.4em;
    margin: auto;
    @include mobile{
      width:90%;
      font-size: 1em;
    }
  }
  .Art{
    height: 30vh;
    margin: auto;
  }

  .Btn{
    .Image{
      width:100%;
      height: 150px;
      position: relative;
      left: 10px;
    }
    position: relative;
    width: 100%;
    text-align: center;
    button{
      padding: 10px;
      background-color: black;
      font-size: 18px;
      border: none;
      cursor: pointer;
      color: white;
    }
  }
  .Down{
    color: white;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
  }
}
